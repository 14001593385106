import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import { Column, Container, Row } from '../elements/Grid';
import { BaseTitle, LgTitle, SmTitle } from '../elements/Titles';
import { BaseParagraph, XsParagraph } from '../elements/Paragraphs';
import { UnorderedList } from '../elements/Lists';

const CookiesPolicy = () => (
  <Layout>
    <SEO title="Cookies Policy" />
    <Container>
      <Row>
        <Column>
          <LgTitle>Cookies Policy</LgTitle>
          <XsParagraph>Last Updated Date</XsParagraph>
          <XsParagraph>
            This policy was last updated on July 24, 2023.
          </XsParagraph>
        </Column>
      </Row>
      <Row hideMobile="true" textAlign="center">
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" marginBottom="0">
            The full policy
          </SmTitle>
        </Column>
        <Column width="1/3">
          <SmTitle dark="true" marginBottom="0">
            The quick reference
          </SmTitle>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>1. Introduction</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            By using this site, you are consenting to our Cookie policy. It will
            change from time to time, so keep an eye on this page. We include
            the revision date on the header on the main page for your easy
            reference.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Welcome to the digital properties of Wise Publishing, Inc., which
            include the Internet sites and software applications that you access
            or download to view Content of Wise Publishing, Inc. (collectively
            "Sites" or each a "Site"). Our Sites use Cookies, as almost all
            websites do, with the intention of providing you with the best
            experience we can.
          </XsParagraph>
          <XsParagraph>
            Unless a Wise Publishing, Inc. Site has its own Cookie Policy, the
            collection, use and disclosure of and by our Site Cookies will be
            governed under the terms of this Cookie Policy (“Cookie Policy”).
          </XsParagraph>
          <XsParagraph>
            This Cookie policy explains how we use cookies and your rights to
            restrict our Cookie use.
          </XsParagraph>
          <XsParagraph>
            We may change this policy without notice from time to time to
            reflect changes in legal or regulatory obligations. By visiting our
            Sites, you agree to accept the policy that is in effect at that
            time. Accordingly, you may wish to check this site from time to
            time. If we make any changes that materially affect personal
            information which we have already collected, we will take reasonable
            steps to notify you.
          </XsParagraph>
          <XsParagraph>
            For California residents, please see our{' '}
            <Link to="/ccpa-addendum/">CCPA Addendum</Link>.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>2. Definitions</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            The definitions are here. If a term doesn't make sense, look here.
            The most important one is the definition of personal information —
            it's that information about an identifiable individual. It includes
            facts, when combined with other information, can identify an
            individual.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            The following words and expressions have the following meanings
            unless inconsistent with the context:
          </XsParagraph>
          <XsParagraph>
            "<strong>Content</strong>"
          </XsParagraph>
          <XsParagraph>
            means any media that can be rendered on a web page intended for
            consumption by an end-user, including, but not limited to articles,
            quizzes, polls, surveys, videos, pictures, audio and embeds;
          </XsParagraph>
          <XsParagraph>
            "<strong>Cookies or LocalStorage</strong>"
          </XsParagraph>
          <XsParagraph>
            means a small amount of data sent from the server which is then
            stored on your computer's internal storage. This policy uses the
            word Cookies to refer to both methods (Cookies and LocalStorage) of
            storing this information;
          </XsParagraph>
          <XsParagraph>
            "<strong>Personal Information</strong>"
          </XsParagraph>
          <XsParagraph>
            means information about an identifiable individual. Personal
            Information includes facts, which, when combined with other
            information, can identify an individual;
          </XsParagraph>
          <XsParagraph>
            "<strong>PIPEDA</strong>"
          </XsParagraph>
          <XsParagraph>
            <em>
              Personal Information Protection and Electronic Documents Act, SC
              2000
            </em>
            , c. 5 (Canada), as amended from time to time;
          </XsParagraph>
          <XsParagraph>
            "<strong>GDPR</strong>"
          </XsParagraph>
          <XsParagraph>
            <em>General Data Protection Regulation</em>, (EU) 2016/679, as
            amended from time to time;
          </XsParagraph>
          <XsParagraph>
            "<strong>we</strong>", "<strong>us</strong>" or "
            <strong>our</strong>"
          </XsParagraph>
          <XsParagraph>
            Wise Publishing, Inc., a company duly incorporated under the laws of
            the province of Ontario;
          </XsParagraph>
          <XsParagraph>
            "<strong>you</strong>", "<strong>your</strong>"
          </XsParagraph>
          <XsParagraph>
            an individual, company or firm accessing our Site.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>3. What our cookies collect</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We collect non-personally identifiable information through Cookies
            to enhance your user experience.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            With the use of Cookies, we collect non-personally identifiable
            information which may include, for example, other websites you
            visit. Our use of Cookies helps us:
          </XsParagraph>
          <UnorderedList>
            <li>make our Sites work as you would expect;</li>
            <li>save you having to login every time you visit our Sites;</li>
            <li>remember your settings during and between visits;</li>
            <li>offer you free services/content (thanks to advertising);</li>
            <li>
              personalize our Sites to you to help you get what you need faster;
              and,
            </li>
            <li>
              store your preferences in relation to the use of Cookies more
              generally.
            </li>
          </UnorderedList>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Third parties help us analyze our website. More details on that are
            below.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            We use third parties to analyze the use and performance of our
            website. Those third parties gather information from our website.
            See below for information about those third parties and their
            privacy policies.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>4. Advertising cookies</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            There are also advertising Cookies. We work only with Advertisers
            that accept certain privacy standards.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            Cookies are widely used in online advertising. We comply with the
            Digital Advertising Alliance of Canada (DAAC) Self-Regulatory Principles
            for Interest-Based Advertising and only work with
            advertising partners who work to accepted privacy standards such as
            those set by the DAAC,
            which can be found at{' '}
            <a
              href="http://www.youradchoices.ca/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.youradchoices.ca
            </a>
            .
          </XsParagraph>
          <XsParagraph>
            Please note that third parties (including, for example advertising
            networks or external services like web traffic analysis services)
            will use Cookies over which we have no control. Information on how
            to block and delete Cookies can normally be found within the help
            features of your browser or links listed at the end of this policy.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            There are ways to block Cookies but it may impact your user
            experience.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            If you use your browser settings to block all Cookies, you may not
            be able to access all or parts of our Sites. Please visit your
            browser's help and support website for more information.
          </XsParagraph>
          <XsParagraph>
            Our third-party advertising partners may employ use of different
            technical ways (such as clear gifs, images, and scripts) that help
            them better manage content on our Sites by measuring activity on
            that page. We do not tie the information gathered to your Personal
            Information.
          </XsParagraph>
          <XsParagraph>
            From time to time we include a link to our advertisers’ privacy
            policies. That information is at the end of this policy.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>5. What we do with the information we gather</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We use Cookies to improve our service to you.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>We use the information gathered by Cookies</XsParagraph>
          <UnorderedList>
            <li>to improve our products and services;</li>
            <li>to optimize our user interface and user experience;</li>
            <li>
              for security purposes to protect your Personal Information held
              and/or processed by us;
            </li>
            <li>
              for training our employees in respect of providing our services to
              you;
            </li>
            <li>
              to prevent, investigate or prosecute activity we think may be
              potentially illegal, unlawful or harmful; and,
            </li>
            <li>
              to enforce our Privacy Policy and/or our Terms of Use or any other
              purpose referenced herein or therein.
            </li>
          </UnorderedList>
          <XsParagraph>
            Cookies differentiate one user from another and pass information
            from page to page during a single user's website session. They are
            read only by the server that placed them and cannot execute any code
            or virus.
          </XsParagraph>
          <XsParagraph>
            Cookies also help us understand which parts of our Web sites are the
            most popular, where our visitors are going and how much time they
            spend there.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            We may sell your information collected by Cookies but in an
            anonymized format only.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            We do not sell your personally identifiable information. We may sell
            your information in an anonymized format. Your personally
            identifiable information will not be available in that format.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>6. GDPR</BaseTitle>
        </Column>
      </Row>
      <Row>
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            If you are a European citizen or within the European Economic Area,
            we have a special Cookie consent for you.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            If we determine you are accessing our Sites from within the EEA
            and/or you are a citizen of the EU, we will ask you to consent to
            our use of Cookies when you first visit our Sites. If you choose not
            to consent, we will need to set a single Cookie to block
            interest-based advertisements and all portions of our Sites that
            legitimately require Cookies for functionality. Please note, if you
            clear Cookies from your browser, your opt-out will not be
            maintained.
          </XsParagraph>
        </Column>
      </Row>
      <Row>
        <Column>
          <BaseTitle>
            7. Information about third party analytics and cookie blocking
          </BaseTitle>
        </Column>
      </Row>
      <Row marginBottom="144">
        <Column width="1/3">
          <SmTitle dark="true" hideDesktop="true">
            The quick reference
          </SmTitle>
          <BaseParagraph>
            Information about third party analytics and links to those third
            parties’ policies and cookie blocking.
          </BaseParagraph>
        </Column>
        <Column orderFirstDesktop="true" width="2/3">
          <SmTitle dark="true" hideDesktop="true" marginBottom="32">
            The full policy
          </SmTitle>
          <XsParagraph>
            We use Google Analytics to analyze the use of our website. Google
            Analytics gathers information about website use by means of Cookies.
            The information gathered relating to our website is used to create
            reports about the use of our website. Google's privacy policy is
            available at:{' '}
            <a
              href="https://www.google.com/policies/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://www.google.com/policies/privacy/
            </a>
            .
          </XsParagraph>
          <XsParagraph>
            We use New Relic to analyze the performance of our website. New
            Relic gathers information about website performance by means of
            Cookies. The information gathered is used to create reports and
            optimize the performance of our website. New Relic's privacy policy
            is available at:{' '}
            <a
              href="http://www.newrelic.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.newrelic.com/privacy
            </a>
            .
          </XsParagraph>
          <XsParagraph>
            At this time, there is no way to prevent Google Analytics or New
            Relic Cookies being set other than to disable Cookies in your
            browser, or not use our Sites. If you have disabled Cookies in your
            browser, you will be denied access to the portions of our Sites that
            legitimately require Cookies for functionality. You can opt-out of
            advertising Cookies with a browser plug-in or browser settings or
            through services provided by the National Advertising Initiative or
            the Digital Advertising Alliance.
          </XsParagraph>
          <UnorderedList>
            <li>
              <a
                href="https://support.google.com/ads/answer/7395996"
                target="_blank"
                rel="noopener noreferrer"
              >
                Browser Plugin
              </a>
            </li>
            <li>
              <a
                href="http://optout.networkadvertising.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                National Advertising Initiative
              </a>
            </li>
            <li>
              <a
                href="http://optout.aboutads.info/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Digital Advertising Alliance
              </a>
            </li>
            <li>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.google.com/chrome/answer/95647?hl=en
              </a>{' '}
              (Chrome);
            </li>
            <li>
              <a
                href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences
              </a>{' '}
              (Firefox);
            </li>
            <li>
              <a
                href="https://help.opera.com/en/latest/security-and-privacy/#tracking"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://help.opera.com/en/latest/security-and-privacy/#tracking
              </a>{' '}
              (Opera);
            </li>
            <li>
              <a
                href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies
              </a>{' '}
              (Internet Explorer);
            </li>
            <li>
              <a
                href="https://support.apple.com/en-ca/guide/safari/sfri11471/mac"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://support.apple.com/en-ca/guide/safari/sfri11471/mac
              </a>{' '}
              (Safari); and,
            </li>
            <li>
              <a
                href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy
              </a>{' '}
              (Edge).
            </li>
          </UnorderedList>
        </Column>
      </Row>
    </Container>
  </Layout>
);

export default CookiesPolicy;
